<template>
  <div>
    <div class="card">
      <!-- LOG TABLE -->
      <DataTable
        ref="dt"
        class="p-datatable-sm"
        :value="alarmLog"
        dataKey="id"
        :paginator="true"
        :rows="50"
        v-model:filters="filters"
        filterDisplay="row"
        sortField="created"
        :sortOrder="-1"
        :globalFilterFields="['label', 'description', 'department', 'category']"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100, 500]"
        currentPageReportTemplate="zeige {first} bis {last} von {totalRecords} LOG-Einträgen"
        responsiveLayout="stack"
        :loading="loading"
        stripedRows
        :getCategory="getCategory"
      >
        <template #loading> Daten werden geladen... bitte warten </template>
        <template #header>
          <div class="table-header flex justify-content-between">
            <h5 class="mb-0">Alarmübersicht</h5>
            <span class="p-input-icon-left"><i class="pi pi-search" /><InputText v-model="filters['global'].value" placeholder="suchen..." /></span>
          </div>
        </template>
        <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
        <Column field="created" header="Zeitstempel" :sortable="true">
          <template #body="slotProps">
            {{ formatDate(slotProps.data.created) }}
          </template>
        </Column>
        <Column field="department" header="Anlage" :sortable="true" filterField="department" :showFilterMenu="false" >
          <template #body="slotProps">
            <span>{{ slotProps.data.department }}</span>
          </template>
          <template v-if="getDepartments !== null" #filter="{filterModel, filterCallback}">
              <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="getDepartments" optionLabel="label" optionValue="key" placeholder="Alle" class="p-column-filter">
                  <template #option="slotProps">
                      <div class="p-multiselect-representative-option">
                          <p>{{ slotProps.option.key }} - <span class="text-xs">{{ slotProps.option.label }}</span></p>
                      </div>
                  </template>
                  <template #value="slotProps">
                      <div class="p-multiselect-representative-option">
                          <p v-if="slotProps.value !== null">{{ getOneDepartment(slotProps.value[0]).key }}  - <span class="text-xs">{{ getOneDepartment(slotProps.value[0]).label }}</span></p>
                          <p v-else>{{ slotProps.placeholder }}</p>
                      </div>
                  </template>
              </MultiSelect>
          </template>
        </Column>
        <Column field="category" header="Kategorie" :sortable="true" filterField="category" :showFilterMenu="false" >
          <template #body="slotProps">
            <span>{{ getOneCategory(slotProps.data.category).label }}</span>
          </template>
          <template v-if="getCategory !== null" #filter="{filterModel, filterCallback}">
              <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="getCategory" optionLabel="label" optionValue="key" placeholder="Alle" class="p-column-filter">
                  <template #option="slotProps">
                      <div class="p-multiselect-representative-option">
                          <p>{{ slotProps.option.label }}</p>
                      </div>
                  </template>
              </MultiSelect>
          </template>
        </Column>
        <Column field="alarmLabel" header="Bezeichnung" :sortable="true" filterField="alarmLabel" :showFilterMenu="false" >
          <template #body="slotProps">
            <span>{{ slotProps.data.alarmLabel }}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <span class="p-input-icon-left"><i class="pi pi-search" /><InputText v-model="filterModel.value" placeholder="suchen..." @keydown.enter="filterCallback()" /></span>
          </template>
        </Column> 
        <Column field="alarmDesc" header="Beschreibung" :sortable="true" filterField="alarmDesc" :showFilterMenu="false" >
          <template #body="slotProps">
            <span>{{ slotProps.data.alarmDesc }}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <span class="p-input-icon-left"><i class="pi pi-search" /><InputText v-model="filterModel.value" placeholder="suchen..." @keydown.enter="filterCallback()" /></span>
          </template>
        </Column> 
        <Column field="alarmValueSp" header="Wert eingestellt" :sortable="true" filterField="alarmValueSp" :showFilterMenu="false" >
          <template #body="slotProps">
            <span>{{ formatValue(slotProps.data.alarmValueSp, slotProps.data.unit, slotProps.data.factor) }}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <span class="p-input-icon-left"><i class="pi pi-search" /><InputText v-model="filterModel.value" placeholder="suchen..." @keydown.enter="filterCallback()" /></span>
          </template>
        </Column> 
        <Column field="alarmValue" header="Wert bei Alarm" :sortable="true" filterField="alarmValue" :showFilterMenu="false" >
          <template #body="slotProps">
            <span>{{ formatValue(slotProps.data.alarmValue, slotProps.data.unit, slotProps.data.factor) }}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <span class="p-input-icon-left"><i class="pi pi-search" /><InputText v-model="filterModel.value" placeholder="suchen..." @keydown.enter="filterCallback()" /></span>
          </template>
        </Column> 
        <!-- <Column field="user" header="Benutzer" :sortable="true" filterField="user" :showFilterMenu="false" >
          <template #body="slotProps">
            <span>{{ slotProps.data.user }}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <span class="p-input-icon-left"><i class="pi pi-search" /><InputText v-model="filterModel.value" placeholder="suchen..." @keydown.enter="filterCallback()" /></span>
          </template>
        </Column>  -->
        <Column field="alarmPrior" header="Priorität" :sortable="true" filterField="alarmPrior" :showFilterMenu="false" >
          <template #body="slotProps">
            <span>{{ formatValue(slotProps.data.alarmPrior, slotProps.data.unit, slotProps.data.factor) }}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <span class="p-input-icon-left"><i class="pi pi-search" /><InputText v-model="filterModel.value" placeholder="suchen..." @keydown.enter="filterCallback()" /></span>
          </template>
        </Column> 
        <Column field="value" header="Zustand" :sortable="true" filterField="value" :showFilterMenu="false" >
          <template #body="slotProps">
            <span v-html="alarmState(slotProps.data.value)"></span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <span class="p-input-icon-left"><i class="pi pi-search" /><InputText v-model="filterModel.value" placeholder="suchen..." @keydown.enter="filterCallback()" /></span>
          </template>
        </Column> 
      </DataTable>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { mapGetters } from "vuex";
import SocketioService from '@/services/socketioService';
import { formatDateLog, formatDateDay, formatValue } from '@/helpers';

export default {
  name: "alarmLog",
  data() {
    return {
      loading: false,
      alarmLog: [],
      filters: null,
      submitted: false,
    };
  },
  created() {
    this.initFilters();
    this.$store.dispatch('types/loadCategoryTypes');
    this.$store.dispatch('types/loadDepartmentTypes');
    this.$store.dispatch('types/loadIconTypes');
  },
  mounted() {
    this.loading = true;
    this.getParameter();
  },
  beforeUnmount() {
    this.alarmLog = [];
    this.filters = null;
    this.submitted = false;
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      getUserGroup: 'users/getUserGroups',
      getCategory: 'types/getCategory',
      getOneCategory: 'types/getOneCategory',
      getDepartments: 'types/getDepartments',
      getOneDepartment: 'types/getOneDepartment',
      getUnits: 'types/getUnits',
      getIcons: 'types/getIcons',
    }),
  },
  methods: {
    getParameter() {
      this.loading = true;
      SocketioService.getAlarmLog((err, response) => {
        if (!err && response !== null) {
          this.loading = false;
          this.alarmLog = response;
        }
      });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'category': { value: null, matchMode: FilterMatchMode.EQUALS },
        'department': { value: null, matchMode: FilterMatchMode.EQUALS },
        'label': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'description': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'alarmLabel': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'alarmDesc': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'alarmValueSp': { value: null, matchMode: FilterMatchMode.EQUALS },
        'alarmValue': { value: null, matchMode: FilterMatchMode.EQUALS },
        'user': { value: null, matchMode: FilterMatchMode.EQUALS },
        'alarmPrior': { value: null, matchMode: FilterMatchMode.EQUALS },
        'value': { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    formatDate(timestamp) {
      return formatDateLog(timestamp);
    },
    formatDateDay(timestamp) {
      return formatDateDay(timestamp);
    },
    formatValue(value, unit) {
      return formatValue(value, unit);
    },
    alarmState(value) {
      if (value === "1") return '<span class="fg-lightRed">kommt</span>';
      else if (value === "0") return '<span class="fg-lightGreen">geht</span>';
      else if (value === "-1") return '<span class="fg-lightBlue">quittiert</span>';
    }
  },
};
</script>

<style lang="scss" scoped>
</style>